// media
$mediaMobile: "screen and (max-width: 768px)";
$mediaTablet: "screen and (max-width: 1024px)";
$mediaDesktopSmall: "screen and (max-width: 1366px)";
$fontFamily: 'Roboto',
sans-serif;
// basic
$bg: #FBFBFD;
$bgDark: #252529;
$borderColor: #EAEDF3;
$borderColorDarker: #D8DCE6;
$borderDefault: 1px solid $borderColor;
$borderColorDark: #2E2E33;
// text
$textMain: rgb(59, 59, 59);
$textBase: #6B6C6F;
$textSecondary: #9EA0A5;
$textLight: #CECFD2;
// colors
// $green: #38B249; // old green
$green: rgb(40, 167, 69);
$blue: rgb(47, 79, 133); // new blue 19102019
// $blue: #1665D8; // old blue
// $orange: rgb(255, 120, 0); // old orange
$orange: rgb(255, 120, 0);
$violet: #6977FF;
$red: rgb(255, 0, 0);
$red2: #E6492D;
$yellow: #FACF55;
$gray: #6B6C6F;
$grey: #6B6C6F;
$lightBlue: #cee4e5;
$success: $green;
$primary: $blue;
$warning: $orange;
$error: $red;
// base color pairs
$colorPairs: (green, #28a745),
(blue, #2f4f85),
(orange, #ff7800),
(violet, #6977FF),
(yellow, #FACF55),
(red, #ff0000),
(gray, #6B6C6F),
(grey, #6B6C6F);
// mixins
@mixin generateColorVariants($class, $property, $alpha) {
  @each $name,
  $color in (green, rgb(40, 167, 69)),
  (blue, rgb(47, 79, 133)),
  (orange, rgb(255, 120, 0)),
  (violet, #6977FF),
  (red, rgb(255, 0, 0)),
  (gray, #6B6C6F),
  (grey, #6B6C6F) {
    #{$class}.#{$name} {
      #{$property}: rgba($color, $alpha);
    }
  }
}

@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
}

@mixin backgroundCover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

// others
$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

// social media icon bg colors
$facebookColor: #3b5999;
$instagramColor: #c54d8c;
$linkedinColor: #0080b1;
$twitterkColor: #00acee;
$pinterestColor: #cb2028;
$youtubeColor: #cc332d;

// version 2
$themeColor1: #0f2850;
$themeColor2: #2f4f85;
$mediaHover: #e7efff;