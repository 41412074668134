@import "../../../../@uik/_vars.scss";

.userRolesContainer { 
    // & * {
    //     scrollbar-width: none;
    // }
    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    overflow-y: auto;
    &.mobile { 
        flex-direction: column !important;
    }
    .ownerContainer { 
        background-color: #f5f8f7;
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .ownerDetails { 
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 1rem 0;
            .gold {
                color: #FFDF00;
            }
        }
        .ownerOutings { 
            display: grid;
            max-height: 200px;
            overflow-y: scroll;
            &.mobile {
                grid-template-columns: 50% 50%;
                justify-content: center;
                .ownerOuting { 
                    // justify-content: center;
                }
            }
            .ownerOuting {
                display: inline-flex;
                align-items: center;
                h3 { 
                    margin-top: 0 !important;
                    margin-left: 1em;
                }
            }
        }
    }

    .mediaSchedules { 
        align-self: center;
        margin-left: 1em;
    }

    .addRoleButton { 
        color: $green;
        &:hover {
            color: $green;
        }
    }
    
    .roleHeadline { 
        display: flex;
        align-items: center;
        justify-content: center;
        &::after { 
            background: $gray;
            height: 1px;
            flex: 1;
            content: '';
            opacity: 0.5;
        }
        &::before { 
            background: none;
        }
        span { 
            flex: 0.1;
        }
    }
}

.usersAssigned { 
    display: grid;
    grid-template-columns: 50% 50%;
    .userAssigned {
        display: inline-flex;
        align-items: center;
        h5 { 
            margin-top: 0 !important;
            margin-left: 1em;
        }
    }
}

.usersList { 
    display: grid;
    .users {
        display: inline-flex;
        align-items: center;
        &:hover, &.selected {
            background-color: $themeColor2;
            cursor: pointer;
            h5 { 
                color: $mediaHover;
            }
        }
        h5 { 
            margin-top: 0 !important;
            margin-left: 1em;
        }
    }
}


.addUserRolesModal { 
    .roles { 
        display: block !important;
        text-align: center;
        // flex-direction: row !important;
        // width: 100%;
        // justify-content: center;
        label {
            margin: 0 0.5em;
        }
    }

    .formInputGroup {
        .searchBarInput {
            flex: 1; 
        }
        .btnSubmit {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background: $themeColor1;
            border: 0;
            color: #fff;
            flex: 0 0 1px;
            min-width: 56px;  
        }
    }
}
.modalMain {
    padding: 15px;
}