@import "@uik/_vars.scss";

.pageWrapper {
    min-height: 100vh;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-height: 700px) {
        justify-content: center;
    }
}
.contentWidgetWrapper {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        width: auto;
        max-width: 90%;
    }
}

.widgetWrapper {
    width: 100%;
    margin: 0 auto 35px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border: none;
}

.stepContent {
    max-width: 500px;
    margin: 0 auto;
}
.stepContentFullWidth {
    max-width: 100%;
}
.plansWrapper {
    margin: 60px auto;
}
.plans {
    display: flex;
    justify-content: space-evenly;

    .plan {
        border: 1px solid $borderColor;
        width: 100%;
        padding: 10px 10px 65px;
        position: relative;
        * {
            color: $themeColor1;
        }
        .planName,
        .planDesc {
            text-align: center;
        }
        .planName {
            font-size: 28px;
            font-weight: 700;
            color: $themeColor1;
        }
        .planDesc {
            margin-top: 10px;
            color: $primary;
        }
        .planPrice {
            margin-top: 25px;
            text-align: center;
            font-size: 56px;
            small {
                font-size: 25px;
                vertical-align: top;
            }
        }
        .planOptions {
            padding: 10px 25px;
            .option {
                line-height: 1.5;
            }
        }

        &.plan2 {
            background: linear-gradient(45deg, rgba(0, 57, 153, 1) 16%, rgba(111, 191, 225, 1) 100%);
            margin-top: -30px;
            margin-bottom: -30px;
            * {
                color: #fff;
            }
        }

        &.plan4 {
            background: linear-gradient(45deg, rgba(208, 225, 255, 1) 16%, rgba(230, 239, 255, 1) 100%);
        }
        .btnWrap {
            text-align: center;
            position: absolute;
            bottom: 15px;
            left: 0;
            width: 100%;
        }
        .btnSelect {
            cursor: pointer;
            padding: 10px 15px;
            border: 3px solid $primary;
            font-weight: 600;
            font-size: 18px;
            background: transparent;
            transition: 0.25s background;

            &:hover,
            &.active {
                background: $primary;
                * {
                    color: #fff !important;
                }
            }
        }
    }
}
.subFields {
    max-width: 500px;
    margin: 0 auto;
}
.cardWrap {
    padding: 8px 15px 7px;
    color: #373737;
    max-width: 100%;
    min-height: 40px;
    border: 1px solid #eaedf3;
}
