@import "../../@uik/_vars.scss";
.linkPreviewContainer {
    position: relative;

}

.linkPreviewBox {
    padding: 7px;
    margin-top: 7px;
    display: flex;
    background: #fff;
    border: 1px solid $borderColor;
    color: $textBase !important;
    &:hover {
        background: rgba($blue, 0.1);
    }

    .imagePreview {
        flex-basis: 100px;
        width: 100px;
        height: 100px;
        display: block;
        background-size: cover;
        background-position: center center;
        background-image: url('../../assets/images/noimage.png');
    }
    .textPreview {
        padding-left: 15px;
        flex-basis: calc(100% - 100px);
        width: calc(100% - 100px);
        min-height: 100px;
        display: inline-flex;
        vertical-align: middle;
        align-items: center;

        .textMainPreview {
            span {
                display: block;
            }
            .textTitle {
                font-size: 18px;
            }
            .textDesc {
                margin-top: 7px;
                font-size: 14px;
            }
        }
    }
}

.btnClose {
    position: absolute;
    top: 7px;
    right: 7px;
}