@import "../@uik/_vars.scss";
.alertWrapper {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    background-color: rgba(#fff, 0.5);

    .alertMessage {
        margin: 0 auto;
        padding: 30px;
        min-width: 150px;

        @each $name, $color in $colorPairs {
            &:global(.#{$name}) {
                background-color: rgba($color, 0.2);
                border: 1px solid $color;
            }
        }
    }
}
.select {
    width: 100%;
}
.btnAction {
    background: rgb(15, 40, 80) !important;
    border-color: rgb(15, 40, 80) !important;
    padding: 0 39px;
    width: auto;
    height: 40px;
}

// socket io
.userTags {
    padding: 2px 15px 10px;
    border: 1px solid $borderColor;
    background-color: $borderColor;
    position: fixed;
    bottom: 0;
    right: 0;
    text-align: center;
    z-index: 9998;
    .userTag {
        margin-right: 2px;

        &:last-child {
            margin-right: 0;
        }
    }
    .withImage {
        top: 7px;
        position: relative;
    }
    .title {
        font-size: 10px;
    }
}

.editableBox {
    display: inline-block;
}

.commentsBox {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
}

.commentsMention {
    background-color: $lightBlue;
}
.stickyFooter {
    border-top: 1px solid $borderColor;
    margin-right: -15px;
    margin-left: -15px;
    position: sticky;
    bottom: 0;
    padding: 15px;
    background: #fff;
}
.navLinkPage {
    border: 1px solid $borderColor;
    // border-top: 0;
    cursor: pointer;
    &:first-child {
        border-top: 1px solid $borderColor;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }
    &:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
}
.iconText {
    .iconTextIcon {
        margin-right: 8px;
    }
}
.paginationContainer {
    padding: 15px;
    border-top: 1px solid $borderColor;
}
