@import "../../../@uik/_vars.scss";

.topBar {
  height: 38px;
  width: 100%;
}
.topBar1 {
  padding-left: 15px;

  .searchForm {
    width: 100%;

    .formInputGroup {
      margin: 0;
      border-radius: 5px;
      background-color: rgb(56, 81, 150);
      display: grid;
      grid-template-columns: 100% auto;
      & > * {
        margin: 0;
      }
      .searchBarWrapper {
        flex: 0 0 calc(100% - 56px);
      }
      .searchBarInput {
        background-color: transparent;
        color: #fff;
        border: 0;
      }
      .btnSubmit {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: rgb(29, 55, 103);
        border: 0;
        color: #fff;
        flex: 0 0 1px;
        min-width: 56px;
      }

      //   rgb(29, 55, 103)
    }
  }
}
.topBar2 {
  justify-content: flex-end;
}
.divider {
  margin-top: -16px;
  height: calc(100% + 30px);
  background: rgb(23, 47, 88);
}
.iconsStyle {
  color: rgb(205, 214, 229);
  font-size: 16px;
}
.navLink {
  color: $textBase;
  padding: 0 8px;

  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
}
.breadcrumbsWrapper {
  padding: 10px 15px;
  background-color: #fbfbfd;
}
.menuList {
  list-style: none;

  .menuItem {
    margin-bottom: 0;
    display: inline-block;

    .menuLink {
      color: $textBase;

      &::after {
        margin: 0 10px;
        content: "/";
        display: inline-block;
      }

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }

    .menuLinkActive {
      color: $blue;
    }
  }

  .menuItem:last-child {
    .menuLink::after {
      display: none;
    }
  }
}

// Quick Links
.quickLinksSectionTitle {
  padding-right: 0;
  padding-left: 0;
  font-size: 24px;
  color: rgb(15, 40, 80);
  text-transform: unset;
  font-weight: 600;
}

.quickLink {
  padding-right: 0;
  padding-left: 0;
  font-size: 16px;
  min-height: 30px;
}
.topbarLogo {
  // flex: 0 0 300px;
  flex: 0 0 200px;
  &.mobile {
    flex: 0 !important;
  }
  display: block;
  text-align: center;
}
.avatarUser {
  & .uik-avatar__avatarWrapper {
    width: 35px;
    height: 35px;
  }
}
.navLinkUser {
  height: 30px;
}
.navLinkAvatar {
  margin: 0 -15px;
}
.topBarDesktop { 
  display: flex;
  width: 100%;
}

.topBarMobile {

}

.burgerMenu {
  position: fixed;
  width: 100%;
  top: 100px;
  background-color: white;
  z-index: 100;
  a {
    padding: 2em;
  }
}
