.mainContainer {
    height: auto;
}

.mobileNavigation {
    background: white;
    display: flex;
    justify-content: space-around;
    flex-shrink: 0;
    position: fixed;
    z-index: 101;
    width: 100%;
    @media screen and (min-width: 1024.1px) {
        display: none;
    }
    .mobileItem {
        justify-content: center;
        flex-grow: 1;
    }
}

.contentContainer {
    width: 100%;
    .content {
        flex-grow: 1;
    }
    &>* {
      transition: .4s all;
    }
    @media screen and (max-width: 1200px) {
        overflow: hidden;
        /* &>* {
        transform: translateX(-270px);
        } */
        &.Home {
            &>* {
                transform: translateX(-270px);
            }
        }
        &.Menu {
            &>* {
                transform: translateX(0);
            }
        }
        &.Chat {
            &>* {
                transform: translateX(-540px);
            }
        }
        .content {
            min-width: 100vw;
            max-width: 100vw;
            transition: 0.4s all;
        }
    }
}
.containerFullWidthPage {
    padding-left: 0;
}