@import "../../@uik/_vars.scss";
.lblPostIn {
  font-size: 14px;
  font-weight: 600;
  color: #373737;
}
.formWrap {
  height: 100%;
  min-height: 100px;
  overflow-y: auto;
  padding: 23px 15px 0;
  position: relative;
}