.pageWrapper {
  min-height: 100vh;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-height: 700px) {
    justify-content: center;
  }
  
}

.widgetWrapper {
  max-width: 730px;
  margin: 0 auto 35px;
  padding: 50px;

  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08);
  border: none;

  .content {

    display: flex;
    align-items: right;
    & > * {
      flex-basis: 365px;
      flex-grow: 1;
      max-width: 365px;
    }


    .left {
      .headline {
        font-size: 1rem;
        padding: 50px;
      }
      .btnAction {
        width: 100%;
        text-align: right;
      }
  
      .inputNameSurnameGroup {
        flex-wrap: nowrap;
      }
    }
  
    .right {
      background: no-repeat center center; 
      background-size: cover;
      //background-image: url("./assets/signin-cover.jpg"); // fallback
      // background-image:  linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,.25) 100%), url("./assets/signin-cover.jpg");
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px;
      * {
        color: white !important;
      }
      .headline {
        font-weight: 500;
        font-size: 1rem;
        i {
          margin-right: 12px;
        }
      }
      .desc {
        font-size: 1rem;
        margin-top: .875rem;
      }


      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

}


