@import '../../../../@uik/_vars.scss';

.iconsStyle {
    color: rgb(205, 214, 229);
    font-size: 16px;
}
.navLink {
    color: $textBase;
    padding: 0 8px;
    position: relative;
    .counterTag {
        font-size: 11px;
        padding: 0 4px;
        position: absolute;
        top: 0;
        left: 15px;
        height: 15px;
    }
}
.dropdownList {
    margin-top: 0;
}
.dropdown {
    min-width: 350px;
    .readAllNotifications { 
        text-align: right;
        padding: 1em 1.5em;
        a { 
            cursor: pointer;
        }
        &:hover {
            color: $blue;
        }
    }
    .dropdownMain {
        max-height: 500px;
    }
    .dropdownFooter {
        position: relative;
        .notificationItem {
            padding: 10px;
            color: $textBase;
            display: block;
            
            .viewAll {
                font-weight: 500;
                font-size: 12px;
                display: block;
            }

            &:hover {
                background: rgba(84, 147, 245, 0.4);
            }
        }
    }
}