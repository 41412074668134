@import "@uik/_vars.scss";
// Background Processes

.iconsStyle {
    color: rgb(205, 214, 229);
    font-size: 16px;
}
.navLink {
    color: $textBase;
    padding: 0 8px;
    position: relative;
    .counterTag {
        font-size: 11px;
        padding: 0 4px;
        position: absolute;
        top: 0;
        left: 15px;
        height: 15px;
    }
}
.dropdownList {
    margin-top: 0;
}
.dropdown {
    min-width: 350px;
    .readAllNotifications {
        text-align: right;
        padding: 1em 1.5em;
        a {
            cursor: pointer;
        }
        &:hover {
            color: $blue;
        }
    }
    .dropdownMain {
        max-height: 500px;
    }
    .dropdownFooter {
        position: relative;
        .notificationItem {
            padding: 10px;
            color: $textBase;
            display: block;

            .viewAll {
                font-weight: 500;
                font-size: 12px;
                display: block;
            }

            &:hover {
                background: rgba(84, 147, 245, 0.4);
            }
        }
    }
}
.notificationItem {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $borderColor;

    &:hover,
    &.highlighted {
        background: rgba(84, 147, 245, 0.05);
    }

    .notificationThumb {
        width: 60px;
        height: 60px;
        background-size: auto 60px;
        background-position: center;
    }
    .notificationThumbSm {
        width: auto;
        height: auto;
        vertical-align: middle;
        display: flex;
        
        .icon {
            font-size: 28px;
        }
    }

    .notificationText {
        padding-left: 10px;
        // max-width: calc(100% - 60px);
        max-width: 100%;
        p {
            margin-top: 0;
            font-size: 0.75rem;
            line-height: 1rem;
            color: $textMain;
        }
        strong {
            font-weight: bold;
        }

        .notificationTimestamp {
            color: $textBase;
        }
    }
}
.notificationItemBig {
    .notificationThumb {
        width: 90px;
        height: 90px;
        background-size: auto 90px;
    }

    .notificationText {
        padding-left: 10px;
        max-width: calc(100% - 90px);
        p {
            margin-top: 0;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $textMain;
        }
        strong {
            font-weight: bold;
        }

        .notificationTimestamp {
            color: $textBase;
        }
    }
}
