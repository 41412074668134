@import "../../@uik/_vars.scss";
.btnGroup {
    // position: relative;
    display: block;
    margin-top: 0;
}
.btnGroupTab {
    border-bottom: 1px solid $borderColor;
}
.btnScheduledPostIcon {
    border-radius: 0 !important;
    border-bottom: 2px solid transparent !important;
    height: 55px;
}
.btnScheduledPostIconActive {
    border-color: $blue !important;
}
.btnPost {
    width: 26px !important;
    flex-basis: 26px !important;
}
.socialAccountItem {
    cursor: pointer;
    border: 2px solid $borderColor;
    border-radius: 5px;
    max-width: 400px;
    opacity: 0.5;

    &.connected,
    &:hover {
        opacity: 1;
        border: 2px solid $borderColorDarker;
    }
}
.socialAccountsWrapper {
    padding: 15px 30px 35px 30px;
}
.modalHeaderText {
    text-align: center;

    h4 {
        font-size: 26px;
    }
}
.avatarName {
    position: relative;
}

.socialAccountItemParent {
    // margin: 5px auto;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    position: relative;
    margin-bottom: 5px;
    // padding: 7px;
    border: 2px solid $borderColor;
    border-radius: 5px;
    // opacity: 0.5;

    &.hasPages {
        max-width: 100%;
    }

    .socialAccountItem {
        max-width: 100%;
        border: 0;
        cursor: pointer;

        &.connected,
        &:hover {
            opacity: 1;
            border-color: $borderColorDarker;
        }
    }
    position: relative;
    padding-right: 35px;

    &.connected,
    &:hover {
        opacity: 1;
        border-color: $borderColorDarker;

        .socialAccountItem {
            opacity: 1;
        }
    }
}
.selectPagesContainer {
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-weight: normal;
    .select {
        width: 100%;
    }
    .pageIcon {
        padding-right: 5px;
        font-size: 16px;
        &.fbPageIcon {
            color: #f37d2c;
        }
        &.piPageIcon {
            color: #e60023;
        }
        &.liPageIcon {
            color: $linkedinColor;
        }
    }
}
.cb {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.navListSection2 {
    // height: 100%;
    // max-height: calc(100vh - 205px);
    flex: 1;

    .navLinkPage {
        border: 1px solid $borderColor;
        // border-top: 0;
        cursor: pointer;
        &:first-child {
            border-top: 1px solid $borderColor;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }
        &:last-child {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }
    }
}
.searchBar {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
    margin-bottom: 15px;

    .selAll {
        font-size: 12px;
        color: $textBase !important;
    }
}

.selectOption {
  margin-top: 10px;
  margin-bottom: 10px;
}

.searchBarMargin{
  margin-top: 5px;
  margin-bottom: 5px;
}