@import "../../@uik/_vars.scss";
.mediaLibraryContainer {
  display: block;
  width: 100%;
  max-width: 1450px;
  background: #fff;
  border: 1px solid $borderColor;
  .filterFileTypeContainer {
    span {
      padding: 4px;
    }
    input {
      margin-right: 0.5rem;
    }
  }
}
.topBar {
  border-bottom: 1px solid $borderColor;
  padding: 10px;
  display: block;
  width: 100%;
}

//clear bar with delete(X) button
.thumbTools {
  display: none;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  // background: rgba(#fff, 0.5);
  z-index: 10;

  .iconRemoveSelected {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 10;
  }
}
.mediaFiles {
  padding: 10px 0;
  // min-height: 640px;
  width: 100%;
  // justify-content: center;
  height: 800px;
  overflow-y: auto;
  &.list {
    display: flex;
    flex-wrap: wrap;
  }
  &.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: 200px;
    @media (max-width: 480px) {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
  }
  &.mobile {
  }
  &.isSelectFiles {
    max-height: 520px;
  }
  &.mediaFilesSlider {
    padding: 7px;
  }
  &.mediaFilesNoWrap {
    display: flex;
    padding: 5px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-x: overlay;
    // width: 606px;
    max-width: 100%;
  }
  .loadMore {
    flex-basis: 100%;
    width: 100%;
  }
}

.fileItem {
  flex-basis: 200px;
  display: flex;
  position: relative;
  &.detailed {
    flex-basis: 50% !important;
    height: 200px;
    &:hover {
      background: rgba($mediaHover, 1);
    }
  }
  .lnkThumbFile {
    height: 200px;
    &.list {
      width: 200px;
    }
    &.grid {
      width: 200px;
    }
    @media (max-width: 480px) {
      margin: 0 auto;
      height: auto;
    }
    position: relative;
    // z-index: 5;
    padding: 5px;
    display: block;
    &.detailed {
      display: inline-block;
    }
    &:hover {
      background: rgba($blue, 0.8);
    }
    &.fileItemSelected::after {
      content: "";
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.6);
    }
    .linkThumbBg {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      img {
        width: 100%;
        height: 100%;
        background: #bababa;
      }
      p {
        display: none;
        animation: all 1s;
        color: white;
      }
      &:hover {
        p {
          display: block;
          animation: all 1s;
          word-break: break-all;
          position: absolute;
          bottom: 0;
          width: 80%;
        }
      }
    }
    .lnkThumbSelected {
      position: absolute;
      top: 15px;
      right: 0px;
      font-size: 1.5em;
      .lnkCheck {
        color: white;
      }
      .lnkCircle {
        color: $blue;
      }
    }
  }

  .linkThumbDetails {
    // display: inline-block;
    // position: absolute;
    word-break: break-all;
    padding: 5px 20px;
    // top: 10%;
    &.desktop {
      height: 100%;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }
    .linkDetails {
      overflow-y: scroll;
      height: 85%;
    }
    strong {
      font-weight: bold;
    }
    .linkThumbChoices {
      position: relative;
      top: 10%;
      .choice {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        &Select {
          color: $green !important;
        }
        &Edit {
          color: $blue !important;
        }
        &Delete,
        &Unselect {
          color: $red !important;
        }
      }
      .breaker {
        display: inline-block;
        padding: 0px 20px;
        font-weight: 900;
        color: black;
      }
    }
  }

  &.fileItemMd {
    flex-basis: 200px;
    .lnkThumbFile {
      height: 200px;
    }
  }

  &.fileItemSm {
    flex-basis: 85px;
    .lnkThumbFile {
      width: 85px;
      height: 85px;
      padding: 5px;
      .linkThumbBg {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .thumbTools2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 6;
    }

    .btnRemoveSelected {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 14px;
      z-index: 7;
      background: #fff;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      line-height: 14px;
    }
  }

  img {
    width: 100%;
    z-index: 10;
  }
  &:not(.dragging):hover {
    .thumbTools {
      display: block;
    }
  }

  &.fileItemSlider {
    flex-basis: 33%;
    a {
      width: 100%;
      height: 110px;
      position: relative;
      z-index: 5;
      padding: 5px;
    }
  }
}
// .fileItem
.heading {
  line-height: 38px;
}

//start- modal open uploaded file
.mImg {
  margin: 0 auto;
  max-width: 100%;
  width: 600px;
  height: auto;
  position: relative;
  z-index: 5;
  padding: 10px;
  display: block;
  &:hover {
    background: rgba($blue, 0.2);
  }
  &.fileItemSelected::after {
    content: "";
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.6);
  }
  .linkThumbBg {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
//end- modal open uploaded file
.colfileDetails {
  background: #fbfbfd;

  .colfileDetailsMain {
    padding: 10px 15px;

    .fileDetailList {
      list-style: none;
    }
  }
}
.dropzoneContainer {
  position: relative;
  outline: 0;
  min-height: 500px;
}

.dropzoneContainerQuickPost {
  position: relative;
  outline: 0;
  // min-height: 60px;
}
.dragActive {
  border-radius: 10px;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($blue, 0.1);
  z-index: 11;
  text-align: center;

  h4 {
    border: 3px solid #fff;
    padding: 15px 30px;
    vertical-align: middle;
    display: inline-block;
    font-size: 24px;
    color: #fff;
    position: absolute;
    top: calc(50% - 44px);
    left: calc(50% - 208px);
  }
}
.dragInActive {
  display: none;
}
.btnUploadContainer {
  border-radius: 10px;
  border: 2px dashed $borderColorDarker;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  display: flex;
  // position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  background: #fbfbfd;
  cursor: pointer;
  height: 275px;
  text-align: center;
  align-items: center;

  &.btnUploadFieldContainer {
    height: auto;
    border: 0;
  }

  .btnUpload {
    color: #fff;
    background: rgb(0, 1, 64);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 0;
  }
}
.modalSelectFilesWidgetContent {
  height: 80%;
  padding: 0;
}
.modalHeader {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid $borderColor;
  z-index: 11;

  .btnClose {
    right: 0;
    top: 13px;
  }
}
.modalSelectFilesFooter {
  // padding: 21px 0;
  width: 100%;
  // height: 80px;
  background: #fff;
  // border-top: 1px solid $borderColor;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 11;
}
.cbFileSelect {
  position: absolute;
  top: 15px;
  right: 8px;
  width: 30px;
  height: 30px;
}
.iconFileSelect {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 7;
  cursor: pointer;
}
.mediaLibrarySelected {
  border: 1px solid $borderColor;
  background: #fff;
  height: auto;
}
.dropbox {
  border: solid 1px red;
  width: 200px;
  height: 200px;
}

.excessImagesContainer {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  color: #fff;
  font-size: 35px;
  font-weight: normal;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  .excessImagesTxtContainer {
    display: table;
    height: 100%;
    width: 100%;
    .excessImagesTxt {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }
}

//play button style
.playBtn {
  height: 60px;
  width: 60px !important;
  background-image: url(../../assets/icons/video.png);
  background-size: 100% 100%;
  //center
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

//delete button style
.deleteBtn {
  height: 60px;
  width: 60px !important;

  //center
  position: absolute;
  right: 10px;
  top: 30px;
}

.viewFileMainContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  &.mobile {
    justify-content: center;
    flex-direction: column;
    .colFileMainDisplay img {
      max-height: 40vh !important;
    }
    .colFileDetails {
      max-height: 50vh;
      min-width: 0 !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .colFileMainDisplay {
    position: relative;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // min-width: 300px;
    // width: 70vw;
    // height: 80vh;
    .mImg {
      width: auto;
      max-height: 90vh;
      padding: 0;
    }
    .mVid {
      // height: 90vh;
      // height: -webkit-fill-available;
    }

    .btnControl {
      display: none;
      z-index: 5;
      position: absolute;
      top: calc(50% - 19px);
      background: rgba(white, 0.3);
      color: rgba(white, 0.3);
      border: 0;
      transition: left 0.3s ease, right 0.3 ease;

      &.btnControlPrev {
        left: 20px;
        &:hover {
          left: 15px;
        }
      }
      &.btnControlNext {
        right: 20px;
        &:hover {
          right: 15px;
        }
      }
      &:hover {
        background: rgba(white, 1);
        color: $textMain;
      }
    }

    &:hover .btnControl {
      display: block;
    }
  }
  .colFileDetails {
    width: 100%;
    min-width: 450px;
    // width: 450px;
    flex-basis: 450px;
    background: #fff;

    .colfileDetailsMain {
      display: flex;
      flex-direction: column;
      height: 100%;
      .header {
        padding: 30px 15px 20px;
        border-bottom: 1px solid $borderColor;
        .close {
          position: relative;
          float: right;
          right: 15px;
          bottom: 5px;
          cursor: pointer;
        }
        .headerMain {
          display: flex;
          position: relative;

          .thumb {
            img {
              width: 100px;
            }
          }

          .details {
            padding: 0 3em 0 1em;
            font-size: 14px;
            word-break: break-all;
            strong {
              font-weight: bold;
            }
          }

          .btnDelete {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: 16px;
          }
        }
      }

      .main {
        padding: 30px 15px 20px;
        height: 100%;
        .fieldGen {
          margin-bottom: 15px;
        }
        .btnLink {
          width: 100%;
          padding-left: 0;
          color: $textMain;
          text-overflow: clip;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .footer {
        padding: 15px;
        border-top: 1px solid #eaedf3;
        .btnSelect {
          float: right;
        }
      }
    }
    // padding: 30px 15px;
  }
}

.widgetWrapper {
  .widgetAvatar {
    border-radius: 20px;
  }
}

.userProfileAvatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // .cameraIcon {
  //   position: absolute;
  //   visibility: hidden;
  //   z-index: 10;
  //   width: 40px;
  //   height: 40px;
  //   transform: translate(0%, 100%);
  //   &:hover {
  //     visibility: visible;
  //   }
  // }
  .userProfileAvatar {
    margin-bottom: 12px;
    -webkit-filter: brightness(100%);
    &:hover {
      cursor: pointer;
      -webkit-filter: brightness(70%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
      // & + .cameraIcon {
      //   visibility: visible;
      //   -webkit-transition: all 1s ease;
      //   -moz-transition: all 1s ease;
      //   -o-transition: all 1s ease;
      //   -ms-transition: all 1s ease;
      //   transition: all 1s ease;
      // }
    }
    // &:hover .cameraIcon {
    //   visibility: visible;
    //   -webkit-transition: all 1s ease;
    //   -moz-transition: all 1s ease;
    //   -o-transition: all 1s ease;
    //   -ms-transition: all 1s ease;
    //   transition: all 1s ease;
    // }
  }
}

.mediaFilesGrid {
  & > :first-child {
    margin: 0 auto;
    position: relative;
  }
  &::-webkit-scrollbar {
    display: block;
  }
}
