@import "../@uik/_vars.scss";

.btnClose {
  margin-top: 0;
  right: 30px;
  top: 19px;
  color: $textSecondary;
  height: 16px;
  line-height: 16px;
  .iconClose {
    font-size: 16px;
    vertical-align: middle;
  }
}
.btnGroup {
  display: block;
}
.btnCancel {
    background-image: none;
    background: #fff;
}
.btnSubmit {
    background-image: none;
    background: #E6492D;
}