@import "../../@uik/_vars.scss";
.mainContainer {
  min-height: 60vh;
  background-color: #fff;
}
.mainContainerQuickPost {
  min-height: 60vh;
}
.widgetQuickPost {
  // margin-top: 30px;
  // margin-left: 15px;
  width: 760px;
  max-width: 100%;
}
.contentWidgetWrapper {
  width: 730px;
  max-width: 100%;
}

.widgetWrapper {
  margin: 15px;
  width: 100%;
}
.widgetStyle {
  padding: 0 !important;
  position: relative;
}

.marginCenter {
  margin-right: auto;
  margin-left: auto;
}

.list {
  padding: 0 0 15px 15px;
  border-right: $borderColor 1px solid;
  position: relative;
}
.listItem {
  padding: 5px;
  border-bottom: $borderColor 1px solid;
  background-color: #fff;
}

.detailTopBar {
  padding: 10px;
  h4 {
    line-height: 38px;
  }
}
.socialColList {
  display: block;
  list-style: none;

  .socialItem {
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 0;
    display: inline-block;
  }
  .socialItem:last-child {
    margin-right: 0;
  }
}
.socialTag {
  border: 1px solid $blue;
  border-radius: 3px;
  padding: 3px 5px;
  display: block;
  background: #fff;
  color: $blue;
  &:hover {
    text-decoration: none;
    background: rgba($blue, 0.1);
  }
  .socialTagIcon {
    margin-bottom: 0;
  }
}
.contentWidgetWrapper {
  width: 730px;
  max-width: 100%;
}

.widgetWrapper {
  margin: 15px;
  width: 100%;
}
.widgetStyle {
  padding: 0 !important;
  position: relative;
}

.marginCenter {
  margin-right: auto;
  margin-left: auto;
}

.selectSocialMedia {
  border: 1px solid $borderColor;
  .inputNameSurnameGroup {
    flex-wrap: nowrap;
  }
}
.inputNameSurnameGroup {
  flex-wrap: nowrap;
}
.socialItemContainer {
  display: flex;
}
.socialItemIcon {
  margin-right: 5px;
  font-size: 16px;
}
.socialItemDetail {
  align-items: center;
}
.socialItemDetailPages {
  padding-left: 30px;
}
.socialListItem {
  list-style: none;
  .socialListItem {
    display: flex;
    padding: 5px 0;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
  }
  .socialListItem:first-child {
    border-top: 1px solid $borderColor;
  }
}

/*---------------------------------------------*/
/*  FACEBOOK
/*---------------------------------------------*/

.greyContainer {
  max-width: 100%;
  background-color: #fbfbfd;
  padding: 50px;
  width: auto;
  display: inline-block;
  text-align: left;
}

.containerFB {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 12px;
  background-color: white;
  font-family: "Arial", sans-serif;
  position: relative;
  border-radius: 3px;
  border: 1px solid $borderColor;
  box-shadow: 1px 1px 10px 0px #9e9e9e;

  .picContainer3 {
    margin-left: -12px;
    position: relative;
    max-width: calc(100% + 24px);
  }

  p {
    color: #1d2129;
    margin: 0px 0px 6px;
  }
  .excessImagesContainer {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    color: #fff;
    font-size: 35px;
    font-weight: normal;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    .excessImagesTxtContainer {
      display: table;
      height: 100%;
      width: 100%;
      .excessImagesTxt {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  .footer {
    border-top: 1px solid #dadde1;
    margin: 10px -12px -12px;
    width: calc(100% + 30px);
    height: 40px;
    display: block;
    background: url("../../assets/images/fb-actions.png");
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.username {
  color: #385898;
  font-weight: 600;
}

.dateFB {
  // font-family: 'Arial', sans-serif;
  p {
    color: #616770;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
}

/*---------------------------------------------*/
/*  TWITTER
/*---------------------------------------------*/

.containerTW {
  padding: 15px 10px;
  margin: 0 auto;
  width: 598px;
  max-width: 100%;
  font-size: 30px;
  background-color: white;
  font-family: "Segoe UI", sans-serif;
  display: flex;
  box-shadow: 1px 1px 10px 0px #9e9e9e;

  .col1 {
    max-width: 49px;
    flex-basis: 49px;
  }
  .col2 {
    position: relative;
    width: 517px;
    max-width: calc(100% - 49px);
    flex-basis: 517px;
    margin-left: auto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    .head {
      margin-bottom: 2px;
      height: 20px;
      align-items: center;
      display: flex;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;

      span {
        color: rgb(101, 119, 134);
        margin-left: 5px;
      }
      span:first-child {
        margin-left: 0;
      }

      .headName {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        color: #000000;
      }

    }
    .content{
      color: rgb(20, 23, 26);
    }
    .picContainer3 {
      margin-top: 10px;
      position: relative;
      max-width: 100%;
    }
    .footer {
      margin-top: 5px;
      width: 100%;
      height: 40px;
      display: block;
      background: url("../../assets/images/tw-actions.png");
      background-position: left center;
      background-repeat: no-repeat;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  p {
    color: #1d2129;
    left: 50px;
  }
}

.usernameTW {
  font-size: 40px;
  word-break: break-all;
  color: #14171a;
  font-size: 14px;
  font-weight: bold;
}

.dateTW {
  // font-family: 'Arial', sans-serif;
  p {
    color: #616770;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
}

/*---------------------------------------------*/
/*  PICTURE TEMPLATE
/*---------------------------------------------*/

// .pictures{
//   padding: 12px;
//   width: auto;
//   background-color: blue;
//   font-family: 'Arial', sans-serif;

//   p{
//     color: #1D2129;
//     margin: 0px 0px 6px;
//   }
// }

/*---------------------------------------------*/
/*  1 picture -facebook
/*---------------------------------------------*/

.picContainer1 {
  background-color: white;

  .row1 {
    display: flex;
  }

  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that sits next to each other */
  .column1 {
    flex: 50%;
    // padding: 10px;
    // height: 300px; /* Should be removed. Only for demonstration */
  }
}

/*---------------------------------------------*/
/*  2 pictures -facebook
/*---------------------------------------------*/

.picContainer2 {
  background-color: white;

  .row2 {
    display: flex;
  }

  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that sits next to each other */
  .column2 {
    flex: 50%;
    // padding: 10px;
    // height: 300px; /* Should be removed. Only for demonstration */
  }
}

/*---------------------------------------------*/
/*  3 pictures -facebook
/*---------------------------------------------*/

.picContainer3 {
  background-color: white;
  position: relative;

  .row3 {
    display: flex;
  }

  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that sits next to each other */
  .column3 {
    flex: 50%;
    // padding: 10px;
    // height: 300px; /* Should be removed. Only for demonstration */
  }
}

/*---------------------------------------------*/
/*  4 pictures -facebook
/*---------------------------------------------*/

.picContainer4 {
  max-width: "100%";
  height: "auto";

  .row4 {
    display: flex;
  }

  * {
    box-sizing: border-box;
  }

  /* Create two equal columns that sits next to each other */
  .column4 {
    flex: 50%;
    // padding: 10px;
    // height: 300px; /* Should be removed. Only for demonstration */
  }
}

/*---------------------------------------------*/
/*  sample sent by martin -crops picture and takes center image
/*---------------------------------------------*/

.parent {
  display: inline-block;
  width: 440px;
  height: 450px;
  overflow: hidden;
  background-color: red;
}

.child {
  max-width: 400px;
  max-height: 400px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.lblPostIn {
  font-size: 14px;
  font-weight: 600;
  color: #373737;
}
.btnState {
  margin-right: 5px;
  color: #fff;
  height: 40px;
  border: 1px solid $borderColor;
}
.formWrap {
  height: 100%;
  overflow-y: auto;
  padding: 23px 15px 0;
  position: relative;
  .buttonGroup {
    margin-right: -15px;
    margin-left: -15px;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    padding: 15px;
    border-top: 1px solid $borderColor;
    z-index: 110;
  }
}