.contentWidgetWrapper {
    width: 730px;
    max-width: 100%;
}
.pageWrapper {
    min-height: 100vh;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-height: 700px) {
        justify-content: center;
    }
}

.widgetWrapper {
    max-width: 730px;
    margin: 0 auto 35px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08);
    border: none;

    .content {

        display: flex;
        align-items: center;
        justify-content: center;
        
        & > * {
        flex-basis: 560px;
        flex-grow: 1;
        max-width: 560px;
        }


        .left {
            .headline {
                font-size: 1rem;
            }
            .btnAction {
                padding: 0 30px; 
                width: auto;
                text-align: center;
            }

            .inputNameSurnameGroup {
                flex-wrap: nowrap;
            }
        }
    }
}