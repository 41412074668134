@import "../../../../@uik/_vars.scss";

.navPanelMain {
  position: fixed;
  top: 0;
  left: 0;
}
.wrapper {
  display: none;
  transition: flex-basis 250ms ease;
  @media screen and (min-width: 1024.1px) {
    display: block;
  }
}
.containerVertical {
  position: relative;
  height: 100vh;

  .containerVerticalMain {
    padding-right: 20px;
    padding-left: 50px;
  }
}
.navBottom {
  border-top: #eaedf3 1px solid;
  padding: 5px;
  background: #fbfbfd;
  width: 100%;
  position: sticky;
  top: 100%;
  left: 0;
  text-align: right;

  a {
    width: 100%;
    display: block;
    font-size: 32px;
  }
  &:hover a {
    color: #1665d8;
  }
}

.sideTopbar {
  background-color: $themeColor1;
  padding-top: 10px;
  padding-bottom: 10px;
}
.textTop {
  font-size: 17px;
  color: rgb(55, 55, 55);
}
.userName {
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  color: $textBase;
}
.avatarUser {
  padding-top: 30px;
  padding-bottom: 30px;
}
.navLink {
  height: 38px;
  min-height: 1px;
  border-bottom: 0;
  .navLinkText {
    padding-left: 15px;
    font-size: 18px;
  }
}