@import "@uik/_vars.scss";

.wizardWrapper {
    position: relative;
    width: 100%;
}

.stepsHeader {
    display: flex;
    justify-content: space-evenly;
}

.stepItem {
    padding: 50px;
    position: relative;
    flex: 0 0 20%;
    text-align: center;
    .num {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 3px solid #fff;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: 700;
        background: $textSecondary;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .name {
        font-size: 1.2rem;
        font-weight: 600;
        color: $primary;
    }

    &:not(:first-child):not(:last-child)::before {
        content: "";
        height: 3px;
        width: 100%;
        position: absolute;
        top: 21px;
        left: 3px;
        background-color: $textSecondary;
    }
    &:first-child::before {
        content: "";
        width: 95%;
        height: 3px;
        position: absolute;
        top: 21px;
        right: -50%;
        background-color: $textSecondary;
    }
    &:last-child::before {
        content: "";
        width: 50%;
        height: 3px;
        position: absolute;
        top: 21px;
        right: 55%;
        background-color: $textSecondary;
    }

    &.active .num {
        color: $textSecondary;
        border-color: $textSecondary;
        background: #fff;
    }
}
