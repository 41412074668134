@import "../../@uik/_vars.scss";

.facebookColor {
    color: $facebookColor;
}
.instagramColor {
  color: $instagramColor;
  // color: #fff;
  // background-image: linear-gradient(-120deg, #ba00b2, #f40001);
  // border-radius: 2px;
}
.linkedinColor {
  color: $linkedinColor;
  border-radius: 2px;
  background-color: #fff;
}
.twitterkColor {
  color: $twitterkColor;
}
.pinterestColor {
  color: $pinterestColor;
}
.youtubeColor {
  color: $youtubeColor;
}
