@import "../../@uik/_vars.scss";

.isMobileViewModalStyle{
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: white;
  max-height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.modalWidgetContainer {
  border: 0;
  box-shadow: none;
  display: flex;
  // align-items: center;
  // height: calc(100% - 132px);
  height: 60vh;
  overflow-y: auto;
  border-radius: 0;
  flex: 1;
}
.modalWidgetContainerFullHeight {
  height: calc(100% - 60px);
  max-height: 100%;
}
.isMobileModalWidgetContainerFullHeight {
  height: calc(100% - 60px);
  max-height: 100% !important;
}
.modalWidgetContent {
  padding: 0;
  flex: 0 0 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
.modalHeader {
  padding: 15px;
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid $borderColor;
  z-index: 11;
  min-height: 50px;
}

.btnClose {
  margin-top: 0;
  right: 15px;
  top: 19px;
  color: $textSecondary;
  height: 16px;
  line-height: 16px;
  .iconClose {
    font-size: 16px;
    vertical-align: middle;
  }
  z-index: 2;
}

.modalHeaderSubText {
  margin-top: 0;
}
.modalSelectFilesFooter {
  padding: 15px;
  width: 100%;
  height: auto;
  background: #fff;
  border-top: 1px solid $borderColor;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 11;
}
.modalHeaderText {
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
}
.showDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#0f2850, 0.5) !important;
  z-index: 901;
}
