@import "../@uik/_vars.scss";
body {
  overflow-y: auto;
  background-color: rgb(244, 247, 246);
  color: $textMain;
  font-size: 14px;
  height: auto;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: $textMain;
}
body strong {
  font-weight: bold;
}
body ul,
body ol {
  list-style-position: inside;
}
.uik-container-h__wrapper {
  position: relative;
}
.list-unstyled {
  list-style: none;
}
// define smaller widgets
.small .uik-widget-title__wrapper {
  padding: 10px 15px;
}
.small .uik-widget-title__wrapper h3 {
  font-size: 1.1em;
}
.small .uik-widget-content__wrapper {
  padding: 15px;
}

.text-bolder {
  font-weight: bolder;
}

// text colors
.white {
  color: #fff;
}
.green,
.success,
.uik-btn__base.uik-btn__clear.green {
  color: $green;
}
.uik-btn__base.uik-btn__clear.green {
  color: $green;
}
.blue,
.primary,
.uik-btn__base.uik-btn__clear.blue {
  color: $blue;
}
.orange,
.warning,
.uik-btn__base.uik-btn__clear.orange {
  color: $orange;
}
.orange,
.uik-btn__base.uik-btn__clear.orange,
.uik-avatar__wrapper .uik-avatar__name i.orange {
  color: $orange;
}
.uik-avatar__wrapper .uik-avatar__name i.fbIcon {
  color: $facebookColor;
}
.violet,
.uik-btn__base.uik-btn__clear.violet {
  color: $violet;
}
.red:not(.uik-tag__fill),
.error,
.uik-btn__base.uik-btn__clear.red {
  color: $red !important;
}
.yellow,
.uik-btn__base.uik-btn__clear.yellow {
  color: $yellow;
}
.dark-gray,
.uik-btn__base.uik-btn__clear.dark-gray {
  color: $textMain;
}
.light-gray,
.uik-btn__base.uik-btn__clear.light-gray {
  color: $textBase;
}
.lighter-gray,
.uik-btn__base.uik-btn__clear.lighter-gray {
  color: $textSecondary;
}
.fab.lighter-gray {
  color: $textSecondary !important;
}

.topBarMain {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: $themeColor1;
  height: 53px;
}
.uik-top-bar__wrapper {
  height: 53px;
}
.topBarMain .uik-menuDrop__list::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid $borderColorDarker;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  top: -8px;
  right: 5px;
  transform: rotate(-45deg);
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.5);
  // background-color: rgba(15, 40, 80, 0.7) !important;
  z-index: 9999;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $blue;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $blue transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  &.loading-container-sm {
    position: absolute;
    z-index: 999;

    .lds-ring {
      position: absolute;
      width: 40px;
      height: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }
    .lds-ring div {
      width: 32px;
      height: 32px;
      margin: 4px;
      border-width: 4px;
    }
  }

  &.loading-container-xs {
    position: absolute;

    .lds-ring {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }
    .lds-ring div {
      width: 16px;
      height: 16px;
      margin: 2px;
      border-width: 2px;
    }
  }

  &.loading-container-plain {
    background-color: transparent;
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.soc-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 40, 80, 0.7) !important;
  z-index: 9999;

  &.soc-loading-container-abs {
    min-height: 50px;
    background-color: rgba(15, 40, 80, 0.5) !important;
    position: absolute;
  }
  .soc-loading {
    position: absolute;
    top: calc(50% - 22.5px);
    left: calc(50% - 29px);

    & > .coll {
      height: 15px;
    }
    & > .coll > .rowl {
      width: 10px;
      height: 10px;
      margin: 0 3px;
      display: inline-block;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
    & > .coll > .rowl.v1 {
      animation-name: lf1;
    }
    & > .coll > .rowl.v2 {
      animation-name: lf2;
    }
    & > .coll > .rowl.v3 {
      animation-name: lf3;
    }
    .d1 {
      animation-delay: 0.111111s;
    }
    .d2 {
      animation-delay: 0.222222s;
    }
    .d3 {
      animation-delay: 0.333333s;
    }
    .d4 {
      animation-delay: 0.444444s;
    }
    .d5 {
      animation-delay: 0.555555s;
    }
    .d6 {
      animation-delay: 0.666666s;
    }
    .d7 {
      animation-delay: 0.777777s;
    }
    .d8 {
      animation-delay: 0.888888s;
    }
    .d9 {
      animation-delay: 0.999999s;
    }

    @keyframes lf1 {
      0% {
        background-color: #38c0ed;
        left: 0px;
        top: 0px;
      }
      25% {
        background-color: #f1f3f4;
        left: 200px;
        top: 0px;
      }
      100% {
        background-color: #38c0ed;
        left: 0px;
        top: 0px;
      }
    }
    @keyframes lf2 {
      0% {
        background-color: #f1f3f4;
        left: 0px;
        top: 0px;
      }
      25% {
        background-color: #38c0ed;
        left: 200px;
        top: 0px;
      }
      100% {
        background-color: #f1f3f4;
        left: 0px;
        top: 0px;
      }
    }
    @keyframes lf3 {
      0% {
        background-color: transparent;
        left: 0px;
        top: 0px;
      }
      25% {
        background-color: #f1f3f4;
        left: 200px;
        top: 0px;
      }
      70% {
        background-color: transparent;
        left: 0px;
        top: 0px;
      }
    }
  }
}
.uik-widget-content__wrapper {
  position: relative;
}
.clickable {
  cursor: pointer;
}
.uik-select__errorHighlight .uik-btn__base {
  border-color: #e6492d;
}

// React Table
.ReactTable {
  font-size: 0.875rem;
}
.ReactTable .rt-th,
.ReactTable .rt-td,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 10px !important;
  white-space: normal !important;
  text-overflow: unset !important;
}

// custom select colors
.uik-select__wrapper {
  @each $name, $color in $colorPairs {
    &:global(.#{$name}) {
      color: #fff;

      & > .uik-select__valueRendered {
        color: #fff;
        border-color: $color;
        background: rgba($color, 0.8);
      }
    }
  }
}
.uik-select__wrapper.green .uik-select__valueRendered {
  color: #fff;
  border-color: $green;
  background: rgba($green, 0.8);
}
.uik-select__wrapper.grey .uik-select__valueRendered {
  color: #fff;
  border-color: $grey;
  background: rgba($grey, 0.8);
}
.uik-select__wrapper.orange .uik-select__valueRendered {
  color: #fff;
  border-color: $orange;
  background: rgba($orange, 0.8);
}
.uik-select__wrapper.blue .uik-select__valueRendered {
  color: #fff;
  border-color: $blue;
  background: rgba($blue, 0.8);
}
.uik-select__arrowWrapper::before,
.uik-select__arrowWrapper::after {
  border-bottom-color: #fff !important;
}

// modal
.ReactModal__Body--open {
  overflow: hidden;
}
.modalMain {
  padding: 30px;
  position: relative;
  height: 100%;
}
.btnClose {
  color: $gray;
  font-size: 30px;
  right: 20px;
  top: 20px;
  position: absolute;
}

// react tabs
.react-tabs__tab-list {
  position: relative;
}
.react-tabs .react-tabs__tab {
  margin-bottom: 0;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}
.react-tabs__tab-panel {
  margin-top: 0;
}
.react-tabs .react-tabs__tab--selected {
  border-color: $borderColorDarker;
  color: black;
  border-radius: 5px 5px 0 0;
}
.react-tabs .react-tabs__tab--selected::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.react-tabs__tab-panel--selected {
  padding: 10px 15px;
  margin-top: 0;
  border: 1px solid $borderColorDarker;
}

// notifications
.notification {
  opacity: 1 !important;
  color: $textMain !important;
  background-color: #fff !important;
  border: 1px solid $borderColor;
  border-left: 3px solid $borderColor;
}
.notification-info {
  border-color: $blue;
}
.notification-info:before {
  color: $blue;
}
.notification-success {
  border-color: $green;
}
.notification-success:before {
  color: $green;
}
.notification-error {
  border-color: $red;
}
.notification-error:before {
  color: $red;
}
.notification-warning {
  border-color: $orange;
}
.notification-warning:before {
  color: $orange;
}

.notification .message {
  margin-top: 0;
}

// alert modal
.soc-modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#0f2850, 0.5) !important;
  z-index: 901;
}
.soc-modal-dialog-body {
  padding: 20px;
  max-width: 34%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  position: absolute;
  top: 20%;
  left: 33%;
  right: 33%;
}

.uik-nav-link-two-container__wrapper {
  padding: 25px 0;
}

// minimize sidebar
.sidebar-minimized {
  width: 60px;
  flex-basis: 60px;
  .uik-nav-user__wrapper .uik-nav-user__avatarWrapper .uik-nav-user__avatar {
    width: 45px;
    height: 45px;
  }
  .uik-nav-link-two-container__wrapper {
    padding: 0;
  }
  .navLink {
    padding: 15px 10px;
    text-align: center;
    display: block;
    min-height: 1px;
  }
  .uik-nav-link-2__text {
    display: block;
  }
  .uik-nav-link-2__icon {
    width: auto;
    margin-right: 0;
  }
  .tablet-nav_navBottom__2RXO8 {
    text-align: center;
  }
  .uik-nav-user__name,
  .uik-nav-user__textTop,
  .navLink .text,
  .uik-nav-section__wrapper {
    display: none;
  }
}

// schedule list minimized
.schedule-list-minimized {
  .uik-avatar__info {
    display: none;
  }
}
.schedule-widget-content {
  .wcol {
    // transition: flex-basis 250ms ease;
  }
}

// input group custom styles
.pull-right-button {
  .uik-input__iconWrapper.uik-input__iconWrapperRight {
    right: 0;
  }
}

// socket io
.socket-wrapper {
  position: relative;
}
.socket-wrapper .socket-users {
  padding-top: 20px;
  position: absolute;
  top: 0;
  right: 0;
}
.socket-wrapper .socket-user {
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
}
.socket-user-tag {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 10px;
  line-height: 14px;
  height: 14px;
}
.socket-users-list {
  display: none;
  padding: 10px;
  border: 1px solid $borderColorDarker;
  background: #fff;
}
.socket-users:hover .socket-users-list {
  display: block;
}
.socket-users-list ul {
  list-style-position: inside;
  font-size: 12px;
}
.socket-users-list::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #d8dce6;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  top: 15px;
  right: 8px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

// widget tools
.top-widget-tools {
  font-size: 18px;
}

// buttons
.btn-violet,
.btn-violet:hover,
.btn-violet:focus {
  color: white;
  background: $violet;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#6977ff), to($violet));
  background-image: -webkit-linear-gradient(bottom, #6977ff 0%, $violet 100%);
  background-image: linear-gradient(0deg, #6977ff 0%, $violet 100%);
  border: 1px solid $violet;
  box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
}

// sidebar minimize
.main-sidebar-nav {
  position: fixed;
  background: transparent;
  flex-basis: 300px;
  width: 300px;
}
.main-content-container {
  padding-top: 53px;
  padding-left: 300px;
  transition: padding-left 250ms ease;
  min-height: 100vh;
}
.sidebar-opened .main-content-container {
  padding-left: 60px;
}

// media schedule
.uik-avatar__wrapper.no-wrap .uik-avatar__textTop,
.uik-avatar__wrapper.no-wrap .uik-avatar__textBottom,
.uik-avatar__wrapper.no-wrap .uik-avatar__name {
  white-space: normal;
}

// edit on click fields
.edit-on-click {
  position: relative;
}
.edit-on-click .uik-input__input {
  padding: 5px 7px;
  font-size: 13px;
}
.edit-on-click .display-text {
  cursor: pointer;
  color: #3e3f42;
  border-bottom: dotted 1px transparent;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: unset;
  word-break: break-word;
  font-size: 12px;
  display: inline-block;
}
.edit-on-click .display-text:hover {
  border-bottom: dotted 1px $blue;
}
.edit-on-click .ipencil {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 10px;
}

.editable-text .react-datepicker-popper {
  min-width: 313px;
}
.icon-eoc-box .edit-on-click {
  display: inline-block;
  width: calc(100% - 20px);
}
.ReactModal__Overlay {
  background: rgba(#0f2850, 0.7) !important;
  // background: #0f2850 !important;
  z-index: 900 !important;
  display: flex;
}
.react-datepicker-popper {
  z-index: 200 !important;
}
.slick-prev:before,
.slick-next:before {
  color: $borderColorDark !important;
}
.slick-arrows-inside .slick-prev {
  left: 10px;
}
.slick-arrows-inside .slick-next {
  right: 10px;
}
.slick-arrows-inside .slick-prev,
.slick-arrows-inside .slick-next {
  z-index: 5;
  font-size: 35px;
  width: 35px;
  height: 35px;
}
.media-file-thumb-selected {
  // z-index: 105;
}
.media-file-thumb-selected.dragging {
  z-index: 920;
}

// generic modal

.genericModalHeader {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid $borderColor;
  z-index: 11;

  .btnClose {
    right: 0;
    top: 13px;
  }
}
.genericModalWidgetContent {
  height: 80%;
}
.genericModalFooter {
  padding: 21px 0;
  width: 100%;
  height: 80px;
  background: #fff;
  border-top: 1px solid $borderColor;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 11;
}
.avatar21px {
  .uik-avatar__avatarWrapper {
    width: 21px !important;
    height: 21px !important;
  }
  .uik-avatar__info {
    margin-left: 8.64px;
  }
  .uik-avatar__name {
    font-size: 11px;
  }
}
.avatar28px {
  .uik-avatar__avatarWrapper {
    width: 28.8px !important;
    height: 28.8px !important;
  }
  .uik-avatar__info {
    margin-left: 8.64px;
  }
}
.avatar32px {
  .uik-avatar__avatarWrapper {
    width: 32px !important;
    height: 32px !important;
  }
}
.avatar35px {
  .uik-avatar__avatarWrapper {
    width: 35px !important;
    height: 35px !important;
  }
}
.avatar40px {
  .uik-avatar__avatarWrapper {
    width: 40px !important;
    height: 40px !important;
  }
}
.avatar42px {
  .uik-avatar__avatarWrapper {
    width: 42px !important;
    height: 42px !important;
  }
}
.avatar48px {
  .uik-avatar__avatarWrapper {
    width: 48px !important;
    height: 48px !important;
  }
}
.avatar49px {
  .uik-avatar__avatarWrapper {
    width: 49px !important;
    height: 49px !important;
  }
}

// generic form

.widgetFormContainer {
  width: 730px;
  max-width: 100%;
}

.widgetFormWrapper {
  margin: 15px;
  width: 100%;
}

.bl-1 {
  border-left: 1px solid $borderColor;
}

.processing-container-sm {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  padding: 10px 25px 0 25px;
  background: #fff;
  border: 1px solid $borderColor;
  .processing-icon {
    position: relative;
    height: 25px;
  }
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.animate-spin {
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uik-nav-panel__wrapper {
  border-right: 0;
}

.avatar-overflow-visible .uik-avatar__name {
  overflow: visible;
}

.avatar-overflow-visible .uik-avatar__name .buttonDisplayBlock {
  position: absolute;
  top: 100%;
  width: 100%;
}

.avatar-overflow-visible .uik-avatar__name .buttonDisplayBlock::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #d8dce6;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  top: -8px;
  right: 5px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.avatar-overflow-visible .uik-avatar__name .buttonDisplayBlock .uik-btn__base {
  display: block;
}
.avatar-sidebar-menu {
  .uik-avatar__avatarWrapper {
    width: 47px;
    height: 47px;
  }
}
.uik-nav-link-2__wrapper .uik-nav-link-2__icon {
  color: rgb(55, 55, 55);
}

.uik-widget__wrapper {
  border-radius: 10px;
}
.small .uik-widget-title__wrapper h3 {
  font-size: 18px;
  color: $themeColor1;
  font-weight: 600;
}
form {
  max-width: 100%;
}
.select__control--is-focused {
  box-shadow: none !important;
  border-color: #1665d8;
}
.select__indicator-separator {
  width: 0 !important;
}
.field-gen .uik-input__input,
.field-gen .select__placeholder {
  font-size: 12px;
  background-color: rgb(244, 247, 246);
}
.field-gen-white.field-gen .uik-input__input,
.field-gen-white.field-gen .select__placeholder {
  background-color: #fff;
}
.field-gen .uik-input__input:focus {
  background-color: #fff;
}
.field-gen .select__control {
  min-height: 40px;
}
.field-gen .select-menu-is-relative .select__menu {
  position: relative;
}
.field-gen .uik-input__input {
  padding: 12px 15px 13px;
  color: rgb(55, 55, 55);
  max-width: 100%;
  min-height: 40px;
}
.field-gen select.uik-input__input {
  padding: 11px 15px;
}
.field-gen .uik-content-title__wrapper {
  font-size: 14px;
  font-weight: 500;
  color: rgb(55, 55, 55);
  text-transform: unset;
}
.field-gen .uik-input__inputFile {
  border-radius: 10px;
  border: 2px rgb(213, 223, 240) dashed;
  background-color: rgb(244, 247, 246);
}
.field-sm .uik-content-title__wrapper {
  font-size: 11px;
}
.field-sm select.uik-input__input {
  padding: 5px 7px;
}
.field-sm .uik-input__input {
  padding: 7px 7px 6px;
}
.avatar-sm {
  .uik-avatar__avatarWrapper {
    width: 30px;
    height: 30px;
  }
}
.avatar-header-top .uik-avatar__name {
  color: #cdd6e5;
}
.__react_component_tooltip.type-info,
.__react_component_tooltip.type-info.place-top:after {
  border-top-color: $facebookColor;
  border: $facebookColor;
}
.avatarSocialAccountItem {
  cursor: pointer;
  // margin: 5px auto;
  padding: 3px;
  border: 2px solid $borderColor;
  border-radius: 5px;
  max-width: 400px;
  background: #fff;
  opacity: 0.5;

  &.connected,
  &:hover,
  &.active {
    opacity: 1;
    border: 2px solid $borderColorDarker;
  }
}
.avatarSocialAccountItem.uik-avatar__wrapper .uik-avatar__info .uik-avatar__name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  overflow: visible;
}
.avatarSocialAccountItem.uik-avatar__wrapper .uik-avatar__info .uik-avatar__textBottom {
  margin-top: 0;
  line-height: 14px;
  font-size: 12px;
  overflow: visible;
  white-space: normal;
}
.avatarSocialAccountItem.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

// loading gradient
.placeholder {
  margin: 0 auto;
  width: 100%;
  min-height: 15px;
  height: 100%;
  background-color: #eee;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  min-height: 15px;
  height: 100%;
  position: relative;
}
:focus {
  outline: none;
}
.icon-account {
  content: url(assets/icons/ico-accounts.svg);
}
.icon-vertical {
  content: url(assets/icons/ico-verticals.svg);
}
.icon-vertical-white {
  content: url(assets/icons/ico-verticals-white.svg);
}
.icon-account-white {
  content: url(assets/icons/ico-accounts-white.svg);
}
.icon-masterschedule {
  content: url(assets/icons/ico-masterschedule.svg);
}

// custom select style
.selectAccountPages__control {
  min-height: 1px !important;
  border-color: $borderColor !important;
  .selectAccountPages__value-container {
    padding-left: 0;
  }
  .selectAccountPages__indicator {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// page title
.pageTitle h3 {
  font-size: 24px;
  color: #0f2850;
  font-weight: 600;
  text-transform: uppercase;
}

// widget group
.widgetGroup {
  display: flex;

  .uik-widget__wrapper {
    border-radius: 0;
    border-right: 0;
  }

  .uik-widget__wrapper:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .uik-widget__wrapper:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid #eaedf3;
  }

  .wcol-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .wcol-1 {
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .wcol-2 {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .wcol-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .wcol-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .wcol-5 {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .wcol-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .wcol-7 {
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .wcol-8 {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .wcol-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .wcol-10 {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .wcol-11 {
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .wcol-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.uik-btn__xs.uik-btn__base.uik-btn__hasIcon .uik-btn__iconWrapper {
  left: 0;
  font-size: 12px;
}
.uik-btn__xs.uik-btn__base.uik-btn__hasIcon .uik-btn__content {
  padding-left: 5px;
}

.uik-nav-section__wrapper .navLink3:not(:last-child) {
  margin-bottom: 5px;
}
.navLink3 {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  color: $blue !important;
  font-weight: bold !important;
  &.active,
  &:hover {
    border-color: $blue;
    box-shadow: none;
  }
  .uik-nav-link__text {
    width: 100%;
    align-items: center;
    display: flex;
    .icon {
      display: inline-block;
      border-radius: 5px;
      padding: 5px;
      width: 36px;
      background: $blue;
    }
    .uik-nav-link__icon {
      width: 36px;
    }
  }
  .fieldWrapper {
    flex: 0 0 calc(100% - 50px);
  }
}

.avatarRoundCorners.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar,
.avatarRoundCorners.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatarRounderCorners.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar,
.avatarRounderCorners.uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.navLink4 {
  padding: 15px;
  // padding: 15px 35px 15px 15px;
  border-bottom: 1px solid $borderColor;
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.navLinkSm {
    padding: 7px;
  }
  .uik-avatar__name {
    font-weight: 600;
  }
  .uik-avatar__textBottom {
    margin-top: 0;
    line-height: 0.75rem;
  }
  .uik-nav-link__text {
    display: inline-block;
  }
}

.truncate {
  // flex: 1;
  min-width: 0;
  *:first-child {
    // @media screen and (max-width: 1029px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // }
  }
  *:last-child {
    text-align: end;
  }
}

.fbColor {
  color: $facebookColor !important;

  .uik-loader-dots__dotloader > div {
    background: $facebookColor !important;
  }
}
.igColor {
  color: $instagramColor !important;
  .uik-loader-dots__dotloader > div {
    background: $instagramColor !important;
  }
}
.liColor {
  color: $linkedinColor !important;
  .uik-loader-dots__dotloader > div {
    background: $linkedinColor !important;
  }
}
.twColor {
  color: $twitterkColor !important;
  .uik-loader-dots__dotloader > div {
    background: $twitterkColor !important;
  }
}
.piColor {
  color: $pinterestColor !important;
  .uik-loader-dots__dotloader > div {
    background: $pinterestColor !important;
  }
}
.ytColor {
  color: $youtubeColor !important;
  .uik-loader-dots__dotloader > div {
    background: $youtubeColor !important;
  }
}

.accordionStyle2 {
  .accordion__button:before {
    display: none;
  }
  .accordion__panel {
    padding: 0;
  }
  .accordion__button {
    font-size: 0.875rem;
    font-weight: bold;
  }
}
.uik-btn__base {
  height: 40px;
}
.uik-btn__base.uik-btn__primary {
  background: #0f2850 !important;
  border-color: #0f2850 !important;
  width: auto;
  height: 40px;
}
.react-datepicker-popper {
  min-width: 313px;
}

.btnCatchOverflow {
  .uik-btn__content {
    text-overflow: clip;
    overflow-y: hidden;
    justify-content: flex-start;
  }
}
.emoji-mart-bar {
  display: none;
}

// emoji picker
.input-w-emoji {
  position: relative;
  z-index: 1;
}
.input-w-emoji .uik-input__iconWrapper:not(.uik-input__iconWrapperRight) {
  left: 0;
}
.emojiPickerContainer {
  display: inline-block;
  position: relative;

  .emoji-mart {
    position: absolute;
    top: 100%;
    left: 0;
  }
  .emoji-mart::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 1px solid #d8dce6;
    border-left: 0;
    border-bottom: 0;
    position: absolute;
    top: -5px;
    left: 8px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

// comments
.commentItemContainer {
  position: relative;
  display: inline-block;
  margin-top: 15px;
}
.commentItem {
  padding: 15px 10px;
  background: #f6f8f7;
  width: 650px;
  max-width: 100%;
  &.commentItemSelf {
    background: #c9d1e4;
  }
  .commenter {
    .commenterName {
      font-weight: bold;
    }
    .commenterTime {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  &.uik-avatar__wrapper .uik-avatar__textBottom {
    white-space: normal;
  }
  .commentText {
    color: $textMain;

    .edit-on-click {
      display: block;
    }
    span {
      white-space: normal;
    }
  }
  .uik-avatar__info {
    position: relative;
    .uik-avatar__name {
      overflow: visible;
    }
  }
}
.commentItem.uik-avatar__wrapper.uik-avatar__small .uik-avatar__avatarWrapper {
  align-self: baseline;
}
.commentItem.uik-avatar__wrapper .uik-avatar__textBottom {
  overflow: visible;
}
.fwCb .uik-checkbox__label {
  width: 100%;
}
.btnCircle {
  border-radius: 50%;
}
.mobileWrapper {
  position: relative !important;
  top: 55px !important;
  width: 100% !important;
  padding-bottom: 10px;
}
.gridResponsive {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.top-75 {
  top: 75px !important;
}
.mobilePaddingBottom {
  padding-bottom: 50px !important;
}
.emoji-mart {
  z-index: 1;
  top: 50% !important;
}
.fullHeight {
  height: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}
.uik-avatar__wrapper .uik-avatar__name i {
  color: inherit;
}
.accordion__button {
  position: relative;
}

// table
.table {
  width: auto;
  tr:first-child {
    th {
      border-top: 1px solid $borderColorDarker;
    }
  }
  th,
  td {
    padding: 8px 30px 8px 0;
    text-align: left;
    border-bottom: 1px solid $borderColorDarker;

    &:last-child {
      padding-right: 0;
    }
  }
}

// dropdown
.postDropDownList {
  position: absolute;
  top: 30px;
  right: -3px;
  font-size: 12px;
  border: 1px solid $borderColor;
  border-radius: 7px;
  background: #fff;

  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #eaedf3;
    background: #fff;
    position: absolute;
    top: -6px;
    right: 8px;
    z-index: 2;
    transform: rotate(45deg);
    border-right: 0;
    border-bottom: 0;
  }
}
.postDropdownOptions {
  position: relative;
  z-index: 1;
}
.postDropDownListItem {
  padding-right: 30px !important;
  padding-left: 20px !important;
  font-size: 12px !important;
  min-height: 24px !important;
  height: 27px !important;
  border: 0 !important;
  border-radius: 7px !important;
  &:hover,
  &:focus {
    border: 0;
    box-shadow: none;
  }
  .icon {
    margin-right: 10px;
  }
}
.btn-group-spaced > .uik-btn__base + .uik-btn__base {
  margin-left: 5px;
}
// .basic-multi-select {
//   .select__menu {
//     display: flex;
//     flex-direction: column;
//   }
// }
@media (max-width: 767px) {
  .uik-form-input-group__horizontal > * {
    flex-basis: 100%;
  }
}

.showScrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: block;
  }
}

.select__menu {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: block;
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: auto !important;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: block;
  }
  max-height: 100% !important;
  height: 100% !important;
}
.uik-avatar__name {
  position: relative;
}

.fa-question-circle {
  color: $blue;
}

.select__menu {
  z-index: 120 !important;
  .select__option--is-selected .uik-avatar__wrapper .uik-avatar__name,
  .select__option--is-selected .uik-avatar__wrapper .uik-avatar__textBottom {
    color: #fff;
    font-size: 12px;
    line-height: 1;
  }
}
.select-socmed {
  z-index: 120 !important;
  .select__option .uik-avatar__wrapper .uik-avatar__name,
  .select__option .uik-avatar__wrapper .uik-avatar__textBottom {
    font-size: 12px;
    line-height: 1;
  }
}
.select__single-value .uik-avatar__wrapper .uik-avatar__name,
.select__single-value .uik-avatar__wrapper .uik-avatar__textBottom {
  font-size: 12px;
  line-height: 1;
}

// small checkbox
.cb-sm.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio)
  .uik-checkbox__label
  .uik-checkbox__checkboxIcon {
  left: 1px;
  top: 4px;
}
.cb-sm.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__label:before {
  text-align: center;
  line-height: 14px;
  padding-left: 1px;
  width: 10px;
  height: 10px;
  content: "";
  font-size: 7px;
  border: 1px solid #dfe3e9;
  border-radius: 3px;
  margin-right: 5px;
  display: inline-block;
  -webkit-transition: border 0.2s, background 0.2s;
  transition: border 0.2s, background 0.2s;
  vertical-align: middle;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}
.cb-sm.uik-checkbox__wrapper .uik-checkbox__label {
  font-size: 11px;
}
.cb-sm.uik-checkbox__wrapper,
.cb-sm.uik-checkbox__wrapper .uik-checkbox__label {
  line-height: 14px;
}
.uik-btn__base.uik-btn__xs.uik-btn__primary {
  height: 28px;
}
.fieldWrapper {
  position: relative;
}
.lbl-trns {
  position: relative;
  cursor: pointer;
  .lbl-trns-btn {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2px;
    background-color: #fff;
    color: $textMain;
    border: 1px solid $borderColorDark;
    line-height: 1;
    z-index: 999;
  }

  &:hover {
    .lbl-trns-btn {
      display: block;
      .fas {
        display: block;
        padding: 1px 2px;
      }
    }
  }
}
.pos-rel {
  position: relative;
}
.small .uik-checkbox__label .type3 {
  font-size: 12px;
}
.small .uik-checkbox__label.blue .type3 {
  color: $blue;
}
.btnConnectSM {
  .uik-nav-link__text {
    display: flex;
    align-items: center;
  }
}