@import '../../@uik/_vars.scss';

.notificationItem {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $borderColor;

    &:hover, &.highlighted {
        background: rgba(84, 147, 245, 0.05);
    }

    .notificationThumb {
        width: 60px;
        height: 60px;
        background-size: auto 60px;
        background-position: center;
    }
    .notificationThumbSm {
        width: 30px;
        height: 30px;
        .icon {
            font-size: 28px;
        }
    }

    .notificationText {
        padding-left: 10px;
        // max-width: calc(100% - 60px);
        max-width: 100%;
        p {
            margin-top: 0;
            font-size: 0.75rem;
            line-height: 1rem;
            color: $textMain;
        }
        strong {
            font-weight: bold;
        }

        .notificationTimestamp {
            color: $textBase;    
        }
    }
}
.notificationItemBig {

    .notificationThumb {
        width: 90px;
        height: 90px;
        background-size: auto 90px;
    }

    .notificationText {
        padding-left: 10px;
        max-width: calc(100% - 90px);
        p {
            margin-top: 0;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $textMain;
        }
        strong {
            font-weight: bold;
        }

        .notificationTimestamp {
            color: $textBase;    
        }
    }
}